export const DataSong = [
  {
    ID: "001",
    Title: "Éclats de lumière",
    Artiste: "Marie-Claire Beaumont",
    isEdit: false,
    VoteNumber: "01",
    Parole: "je lui dirai qu'il est de ce beau pays",
  },
  {
    ID: "002",
    Title: "Voyage en solitaire",
    Artiste: "Antoine Delacroix",
    isEdit: true,
    VoteNumber: "02",
    Parole: "le voyage solitaire",
  },
  {
    ID: "003",
    Title: "Cœur en détresse",
    Artiste: "Léa Martin",
    isEdit: false,
    VoteNumber: "03",
    Parole: "",
  },
  {
    ID: "004",
    Title: "Rythme effréné",
    Artiste: "Maxime Dubois",
    isEdit: false,
    VoteNumber: "04",
    Parole: "Je suis fatigue de manger",
  },
  {
    ID: "005",
    Title: "Lueur d'espoir",
    Artiste: "Camille Lambert",
    isEdit: true,
    VoteNumber: "05",
    Parole: "Bonjour Je suis laba",
  },
  {
    ID: "006",
    Title: "Mélodie du crépuscule",
    Artiste: "Élodie Rousseau",
    isEdit: false,
    VoteNumber: "06",
    Parole: "",
  },
  {
    ID: "007",
    Title: "Tempête de sentiments",
    Artiste: "Lucas Moreau",
    isEdit: false,
    VoteNumber: "07",
    Parole: "",
  },
  {
    ID: "008",
    Title: "Danse sous la pluie",
    Artiste: "Chloé Girard",
    isEdit: true,
    VoteNumber: "08",
    Parole: "",
  },
  {
    ID: "009",
    Title: "Vers l'infini",
    Artiste: "Théo Bernard",
    isEdit: false,
    VoteNumber: "09",
    Parole: "",
  },
  {
    ID: "010",
    Title: "Échos du passé",
    Artiste: "Manon Dupont",
    isEdit: false,
    VoteNumber: "10",
    Parole: "",
  },
  {
    ID: "011",
    Title: "Brise légère",
    Artiste: "Hugo Lefèvre",
    isEdit: true,
    VoteNumber: "11",
    Parole: "",
  },
  {
    ID: "012",
    Title: "Symphonie du bonheur",
    Artiste: "Clara Durand",
    isEdit: false,
    VoteNumber: "12",
    Parole: "",
  },
  {
    ID: "013",
    Title: "Nuits d'été",
    Artiste: "Gabrielle Morel",
    isEdit: false,
    VoteNumber: "13",
    Parole: "",
  },
  {
    ID: "014",
    Title: "Espoir retrouvé",
    Artiste: "Nicolas Giroux",
    isEdit: true,
    VoteNumber: "14",
    Parole: "",
  },
  {
    ID: "015",
    Title: "Danse avec moi",
    Artiste: "Sophie Lefebvre",
    isEdit: false,
    VoteNumber: "15",
    Parole: "Bonjour je suis la",
  },
  {
    ID: "016",
    Title: "Au clair de lune",
    Artiste: "Julien Lambert",
    isEdit: false,
    VoteNumber: "16",
    Parole: "",
  },
  {
    ID: "017",
    Title: "Rêves d'ailleurs",
    Artiste: "Émilie Bertrand",
    isEdit: true,
    VoteNumber: "17",
    Parole: "",
  },
  {
    ID: "018",
    Title: "Évasion nocturne",
    Artiste: "Lucie Martin",
    isEdit: false,
    VoteNumber: "18",
    Parole: "",
  },
  {
    ID: "019",
    Title: "Chanson d'amour",
    Artiste: "Alexandre Dupuis",
    isEdit: false,
    VoteNumber: "19",
    Parole: "laba ou tu es",
  },
  {
    ID: "020",
    Title: "Reflets du passé",
    Artiste: "Manon Dubois",
    isEdit: true,
    VoteNumber: "20",
    Parole: "",
  },
  {
    ID: "021",
    Title: "Sous les étoiles",
    Artiste: "Charlotte Leroy",
    isEdit: false,
    VoteNumber: "21",
    Parole: "",
  },
  {
    ID: "022",
    Title: "Mélancolie d'automne",
    Artiste: "Thibault Renault",
    isEdit: true,
    VoteNumber: "22",
    Parole: "",
  },
  {
    ID: "023",
    Title: "Envolée lyrique",
    Artiste: "Laura Petit",
    isEdit: false,
    VoteNumber: "23",
    Parole: "",
  },
  {
    ID: "024",
    Title: "Sérénade sous la pluie",
    Artiste: "Mathis Dubois",
    isEdit: false,
    VoteNumber: "24",
    Parole: "",
  },
];

export const FetchedData = async () => {
  const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/music`, {
    method: "GET",
    headers: {
      Authorization: "Bearer 12345",
      "Content-Type": "application/json; charset=UTF-8",
    },
    //   body: JSON.stringify(data.username),
  });
  const results = await response.json();
  return results;
};

export const FetchFinalData = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_DOMAIN}/api/finalplaylist`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer 12345",
        "Content-Type": "application/json; charset=UTF-8",
      },
      //   body: JSON.stringify(data.username),
    }
  );
  const results = await response.json();
  return results;
};

export const PostData = async (data) => {
  return await fetch(`${process.env.REACT_APP_DOMAIN}/api/insertvote`, {
    method: "POST",
    headers: {
      Authorization: "Bearer 12345",
      "Content-Type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((result) => {
      return result; // Retournez la réponse si nécessaire
    })
    .catch((error) => {
      console.error("Erreur:", error);
      throw error;
    });
};
