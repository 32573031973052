import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { useSWRConfig } from "swr";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [hasVoted, setHasVoted] = useLocalStorage("hasUserVoted", false);
  const [token, setToken] = useLocalStorage("mytoken", null);
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();

  const clearCache = () => mutate(() => true, undefined, { revalidate: false });

  // call this function when you want to authenticate the user
  const login = async (data) => {
    const response = await fetch(
      `${process.env.REACT_APP_DOMAIN}/api/logtest`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mytoken")}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(data.username),
      }
    );
    const result = await response.json();
    const header = await response.headers.getSetCookie();
    if (!isEmpty(result.answer)) {
      setUser(data);
      setToken(result.answer);
      localStorage.setItem("mytoken", result.answer);
      localStorage.setItem("currentpass", data.username);
      if (hasVoted) {
        // console.log("entered");
        navigate("/videoPage");
      } else navigate("/hintPage");
    }
    return response;
  };
  const isEmpty = (val) => {
    return val === undefined || val == null || val === false || val.length <= 0
      ? true
      : false;
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    clearCache();
    // localStorage.removeItem("mytoken");
    localStorage.setItem("hasUserVoted", false);
    setHasVoted(false);

    navigate("/login");
  };

  const updateUserStatus = async (data) => {
    const response = await fetch(
      `${process.env.REACT_APP_DOMAIN}/api/logtest`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mytoken")}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
        // body: JSON.stringify(data),
      }
    );
    const result = await response.json();
    if (result.answer === true) {
      setHasVoted(true);
      localStorage.setItem("hasUserVoted", true);
      // navigate("/videoPage");
    } else {
      // console.log(response.message, "  ", response.status);
    }
  };

  const value = useMemo(
    () => ({
      user,
      hasVoted,
      login,
      logout,
      updateUserStatus,
    }),
    [user, hasVoted]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
