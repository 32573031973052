import "../App.css";
import React, { useEffect, useState, useRef } from "react";
import { Box, Typography, Checkbox } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BackgroundImage from "../BackgroundImage ";
import ImageBackground from "../assets/jenifer_cover_black.jpg";
import ImageBackgroundWeb from "../assets/jenifer_cover_black.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FetchedData } from "../dataSong";
import { PostData } from "../dataSong";
import CustomModal from "../components/modal/customModal";
import moment from "moment";
import "moment/locale/fr";
import useSWR from "swr";
import { useAuth } from "../hooks/useAuth";
import SpeechModal from "../components/modal/SpeechModal";
import VirtualizedList from "../components/VirtualizedList";

const fetcher = (...args) => fetch(...args).then((res) => res.json());
const url = `${process.env.REACT_APP_DOMAIN}/api/endVoteTime`;

const ComputeFinalPlaylist = () => {
  const [selectedSongs, setSelectedSongs] = useState(Array(6).fill(null));
  const [open, setOpen] = useState(false);
  const [selectedGrid, setSelectedGrid] = useState(null);
  const [endTime, setEndTime] = useState(new Date().getTime() + 1 * 15 * 1000);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [fontSize, setFontSize] = useState(10); // Taille de police initiale
  const [isOverflow, setIsOverflow] = useState(false); // État pour indiquer si le texte dépasse
  const boxRef = useRef(null); // Référence pour la boîte
  const textRef = useRef(null); // Référence pour le texte
  const [infoPlayList, setInfoPlaylist] = useState([]);
  const [formaData, setFormData] = useState("");
  const [years, setYear] = useState("");
  const [isLoadingC, setIsLoadingC] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isCustomOpenModal, setCustomModal] = useState(false);

  //
  const [isOpenModal, setModal] = useState(false);
  const [changeColor, setColor] = useState(false);

  const [paroles, setParole] = useState();
  // const [changeColor,  setColor] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [remoteData, setRemoteData] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [tabFinalMusic, setTabFinalMusic] = useState([]);
  const [tabCompare, setTabCompare] = useState([]);

  const { logout } = useAuth();

  const { data, error, isLoading } = useSWR(url, fetcher, {
    revalidateOnFocus: true,
  });
  if (data?.result === true) {
    logout();
  }
  if (data?.gotCode !== undefined) {
    if (data?.gotCode !== localStorage.getItem("currentpass")) {
      // console.log("current password:", localStorage.getItem("currentpass"));
      // console.log("passfrom store:", data?.gotCode);
      // console.log("date reached from pass");
      logout();
    }
  }

  // console.log("data from swr:", data);

  const handleCustomCloseModal = () => {
    setCustomModal(false);
  };

  // fetch data
  const fetchedData = async () => {
    try {
      // console.log("hasvotedUser:", localStorage.getItem("hasUserVoted"));
      // setIsLoadingData(true);
      const results = await FetchedData();
      // console.log("fetched results ", results);
      const filteredResults = results.getmusics.filter(
        (c) => !c.isAdded && c.isChoice
      );

      const originalDate = results.playListInfo.dateFinVote;
      const formattedDate = moment(originalDate)
        .locale("fr")
        .format("DD MMMM YYYY");

      // Get the current date

      // Calculate the difference in hours between the current date and the original date
      setEndTime(moment(originalDate.toString().slice(0, -1)).utc().valueOf());

      setFormData(formattedDate);
      setYear("2025");

      setInfoPlaylist(results.playListInfo);
      const tabchoice = [];
      const chunkedData = [];
      chunkedData.push(filteredResults);
      chunkedData.forEach((array) => {
        array.sort((a, b) => a.position - b.position);
      });
      setRemoteData(chunkedData);
      // console.log("chunked", chunkedData);

      chunkedData.forEach((element) => {
        element.forEach((elt) => {
          if (elt.songId.length > 0) {
            tabchoice.push(elt);
          }
        });
      });
      // console.log("taille ===> ", tabchoice.length);
      setTabCompare(tabchoice);
      // console.log("chunked", chunkedData);

      if (filteredResults.length > 0) {
        setIsLoadingData(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchedData();
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModal(false);
  };

  const handleConfirmModal = async () => {
    try {
      setIsLoadingC(true);
      const response = await PostData(tabFinalMusic);

      // console.log("response", JSON.stringify(response));
      // Utilisez la réponse si nécessaire
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingC(false); // Désactiver le loader
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    const boxHeight = boxRef.current?.clientHeight; // Hauteur de la boîte
    const textHeight = textRef.current?.clientHeight; // Hauteur du texte

    // Si la hauteur du texte est supérieure à la hauteur de la boîte
    if (textHeight > boxHeight) {
      setIsOverflow(true); // Définir l'état de débordement sur true
      setFontSize((prevSize) => prevSize - 1); // Diminuer la taille de police
    } else {
      setIsOverflow(false); // Définir l'état de débordement sur false
    }
  }, [fontSize]);

  const handleOpen = (gridIndex, songId) => {
    setSelectedGrid(gridIndex); // Mettre à jour l'index de la grille sélectionnée
    setModalData(songId);
    setOpen(true);
  };

  const handleParoles = (Parole) => {
    setParole(Parole);
    setCustomModal(true);
  };
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const remaining = endTime - currentTime;
      if (remaining < 1000) navigate("/finalplaylist");
      setTimeRemaining(remaining);
    }, 1000); // Mettre à jour toutes les secondes

    return () => clearInterval(interval); // Nettoyer le setInterval lorsque le composant est démonté
  }, [endTime]); // [endTime] pour réinitialiser l'interval lorsque l'heure de fin change

  const hours = Math.floor(timeRemaining / (1000 * 60 * 60));
  const minutes = Math.floor((timeRemaining / (1000 * 60)) % 60);
  const seconds = Math.floor((timeRemaining / 1000) % 60);

  const formattedTime = () => (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {hours !== 0 && (
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ fontSize: "larger", height: "33px" }}>
              {("0" + hours).slice(-3)}
            </span>
            <span style={{ fontSize: "xx-small" }}>hour</span>
          </div>
          <span style={{ margin: "0px 5px" }}>:</span>
        </>
      )}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={{ fontSize: "larger", height: "33px" }}>
          {("0" + minutes).slice(-2)}
        </span>
        <span style={{ fontSize: "xx-small" }}>mins</span>
      </div>
      <span style={{ margin: "0px 5px" }}>:</span>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={{ fontSize: "larger", height: "33px" }}>
          {("0" + seconds).slice(-2)}
        </span>
        <span style={{ fontSize: "xx-small" }}>secs</span>
      </div>
    </div>
  );

  return (
    <BackgroundImage
      imageUrlMobile={ImageBackground}
      imageUrlDesktop={ImageBackgroundWeb}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // my: 4,
          // width: window.innerWidth >= 769 ? "60%" : "90%",
          margin: "auto",
          paddingTop: 1,
          alignContent: "center",
        }}
      >
        <Box
          sx={{
            height: 40,
            "@media (min-height:740px)": {
              marginBottom: "50px",
            },
            "@media (min-height:800px)": {
              marginBottom: "84px",
            },
            "@media (min-height:900px)": {
              marginBottom: "100px",
            },
          }}
        >
          <Typography
            style={{
              color: "#FF8E6C",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              lineHeight: "18px",
            }}
          >
            <span className="poppins" style={{ fontWeight: "bold" }}>
              {infoPlayList?.nomplaylis}
            </span>
            {/* <br /> */}
            <span className="poppins" style={{ fontWeight: "regular" }}>
              {formaData}
            </span>
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="h2"
            style={{
              color: "rgb(251, 221, 46, 0.9)",
              textAlign: "center",
              textShadow: "rgb(0, 0, 0) 1px 1px 7px",
            }}
          >
            <span className="budmo">JUKE BOX</span>
          </Typography>
          <Typography
            variant="h2"
            style={{
              color: "#fff",
              textAlign: "center",
              marginTop: -28,
              marginBottom: 10,
            }}
          >
            <span
              className="budmo"
              style={{
                color: "rgb(251, 221, 46, 0.9)",
                textShadow: "rgb(0, 0, 0) 1px 1px 7px",
              }}
            >
              TOUR <span className="octothorpe">{years}</span>
            </span>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "-1rem",
          }}
        >
          <Typography
            variant="h5"
            component="h4"
            sx={{
              background: "#FFFFFF",
              padding: 1,
              borderRadius: 2,
              color: "rgb(255, 142, 108)",
              textAlign: "center",
              width: "100%",
            }}
          >
            <span className="scuba">{formattedTime()}</span>
          </Typography>
        </Box>
        <Typography
          variant="h7"
          component="h6"
          style={{ color: "#fff", textAlign: "center" }}
          gutterBottom
        >
          <span>plus que quelques instants</span>
          <br />
          <span>avant de découvrir la playlist définitive!</span>
        </Typography>
        <VirtualizedList
          remoteData={remoteData}
          boxRef={boxRef}
          selectedSongs={selectedSongs}
          handledes={handleParoles}
          handleOpen={handleOpen}
          fontSize={fontSize}
          isComputePage={true}
        />
      </Box>
      <CustomModal
        isOpen={isModalOpen}
        tabFinalMusics={tabFinalMusic}
        onClose={handleCloseModal}
        onConfirm={handleConfirmModal}
        isLoading={isLoadingC}
      />
      <SpeechModal
        handleCustomCloseModal={handleCustomCloseModal}
        paroles={paroles}
        isCustomOpenModal={isCustomOpenModal}
      />
    </BackgroundImage>
  );
};

export default ComputeFinalPlaylist;
