import React from 'react';
import ReactPlayer from 'react-player';

const FullscreenVideo = ({url, reactPlayerProps}) => {
  return (
    <div style={styles.wrapper}>
      <ReactPlayer
        url={url}  // Remplacez par l'URL de votre vidéo
        playing  // Lecture automatique
        loop     // Vidéo en boucle (si nécessaire)
        controls // Afficher les contrôles
        width="100%"  // Prend 100% de la largeur
        height="100%" // Prend 100% de la hauteur
        style={styles.player}
        {...reactPlayerProps}
      />
    </div>
  );
};

const styles = {
  wrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 1,
    backgroundColor: 'black', // Optionnel, pour un fond noir derrière la vidéo
  },
  player: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
};

export default FullscreenVideo;
