import React, { useState, useEffect } from "react";

const CountdownTimer = ({
  setIsVoteTime = null,
  startTime, // Timestamp de l'heure de début
  endTime, // Timestamp de l'heure de fin
  size = 200, // Taille du cercle
  textColor = "#FFFFFF", // Couleur du texte
  strokeColor = "#FFFFFF", // Couleur du contour du cercle (noir)
  strokeBgColor = "#000000", // Couleur du contour en arrière-plan (blanc)
  strokeWidth = 8, // Largeur du contour
  className = "din", // pour le font
  showText = true,
}) => {
  const [timeRemaining, setTimeRemaining] = useState(endTime - Date.now());
  const totalTime = endTime - startTime;

  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      setTimeRemaining(startTime - now);

      if (now >= startTime) {
        if (setIsVoteTime) setIsVoteTime(true);
        const remaining = endTime - now;
        //clearInterval(interval);
        setTimeRemaining(remaining);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [startTime, endTime]);

  // Calcul du pourcentage de progression
  const percentage = Math.max(0, (timeRemaining / totalTime) * 100);

  // Calcul des dimensions et des propriétés du cercle SVG
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = (percentage / 100) * circumference;

  // Fonction de formatage du temps (heures, minutes, secondes)
  const formatTime = (timeInMs) => {
    const totalSeconds = Math.floor(timeInMs / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };

  return (
    <div
      className={className}
      style={{ position: "relative", width: `${size}px`, height: `${size}px` }}
    >
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        style={{ transform: "rotate(270deg)" }} // Rotation pour démarrer à partir du bas
      >
        {/* Définition du dégradé pour la bordure - TEST */}
        <defs>
          <linearGradient
            id="progressGradient"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
          >
            <stop offset="0%" stopColor={strokeColor} />
            {/* <stop offset="80%" stopColor={strokeBgColor} />
              <stop offset="90%" stopColor={strokeColor} /> */}
            <stop offset="100%" stopColor={strokeColor} />
          </linearGradient>
        </defs>

        {/* Cercle de fond (pour l'arrière-plan du contour) */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke={strokeBgColor}
          strokeWidth={strokeWidth}
          fill="transparent"
        />

        {/* Cercle de progression avec un léger dégradé */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke="url(#progressGradient)" // Application du dégradé
          strokeWidth={strokeWidth}
          fill="transparent"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          style={{ transition: "stroke-dashoffset 1s linear" }} // Animation fluide
        />
      </svg>

      {/* Texte du décompte */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: textColor,
        }}
      >
        {showText && (
          <p style={{ fontSize: `${size / 10}px`, textTransform: "none" }}>
            Il vous reste
          </p>
        )}
        <h1 style={{ fontSize: `${size / 5}px`, margin: "0px" }}>
          {formatTime(timeRemaining)}
        </h1>
        {showText && (
          <p style={{ fontSize: `${size / 10}px`, textTransform: "none" }}>
            Pour voter
          </p>
        )}
      </div>
    </div>
  );
};

export default CountdownTimer;
