import { Center, Spinner } from "@chakra-ui/react";
import { Box, Typography } from "@mui/material";

import { useState, useEffect } from "react";
import { useAuth } from "../hooks/useAuth";
import BackgroundImage from "../BackgroundImage ";
import ImageBackground from "../assets/jenifer_cover_black.jpg";
import ImageBackgroundWeb from "../assets/jenifer_cover_black.jpg";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FilledInput from "@mui/material/FilledInput";
import Button from "@mui/material/Button";

export const LoginPage = () => {
  const [username, setUsername] = useState("");
  const hashedCode = "●".repeat(username.length);
  // const [password, setPassword] = useState("");
  const [message, setMessage] = useState(false);
  const [years, setYear] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const { login } = useAuth();
  const value = 4;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    setYear("2025");
  }, []);
  const handleLogin = async (e) => {
    e.preventDefault();
    setisLoading(true);
    const results = await login({ username });

    if (results.status === 403) {
      setMessage("Code Incorrect");
      setisLoading(false);
    } else {
      setisLoading(false);
    }
    if (results.status === 404) {
      setMessage("Concert Terminé");
      setisLoading(false);
    } else {
      setisLoading(false);
    }
  };

  const handleChangeCode = (event) => {
    const currentInput = event.target.value;
    const lastChar = currentInput.slice(-1);

    // Met à jour le mot de passe réel
    if (currentInput.length > username.length) {
      setUsername((username) => (username += lastChar));
    } else {
      setUsername((username) => username.slice(0, -1));
    }
  };

  return (
    <BackgroundImage
      imageUrlMobile={ImageBackground}
      imageUrlDesktop={ImageBackgroundWeb}
      // style={{ backgroundSize: "100%",
      // backgroundAttachment: "fixed",
      // width: "100%",
      // height: "100vh",}}
    >
      <div
        style={{
          height: "100vh",
          maxHeight: "100vh",
          paddingTop: "220px",
          overflowY: "hidden",
        }}
      >
        <div
          style={{
            backgroundColor: "rgba(0,0,0,0.3)",
            color: "white",
            margin: "0 auto",
            padding: "5px",
            width: "200px",
          }}
        >
          <Typography
            variant="h2"
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "24px",

              textShadow: "rgb(0, 0, 0) 1px 1px 7px",
            }}
          >
            <span className="allenoire">
              JUKEBOX TOUR <span className="allenoire">{years}</span>
            </span>
          </Typography>
          <Typography
            variant="h3"
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "12px",

              textTransform: "uppercase",

              textShadow: "rgb(0, 0, 0) 1px 1px 7px",
            }}
          >
            <span className="din">Votre playlist votre concert unique</span>
          </Typography>
        </div>

        <div
          className="login-container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // height: "100vh",
          }}
        >
          <form
            onSubmit={handleLogin}
            style={{
              width: "100%",
              padding: "20px",
              marginTop: "20px",
              // border: "1px solid #ccc",
              borderRadius: "4px",
              backgroundColor: "transparent",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isLoading && (
                <Spinner
                  thickness="30px"
                  speed="0.65s"
                  // emptyColor='gray.200'
                  // color="#A14699"
                  color="rgba(219, 97, 47, 0.75)"
                  size="xl"
                />
              )}
            </div>

            <div
              className="form-group"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "50px",
              }}
            >
              <label
                htmlFor="Code"
                className="din"
                style={{
                  display: "block",
                  marginBottom: "5px",
                  fontSize: "14px",
                  textTransform: "uppercase",
                  textAlign: "center",
                  color: "#FFFFFF",
                  backgroundColor: "rgba(0,0,0,0.0)",
                  padding: "1px",
                }}
              >
                Rentrez votre mot de passe <br /> pour accéder à la playlist et
                sélectionner vos <br /> titres
              </label>

              {/* <input
              style={{
                width: "100%",
                color: "black",
                maxLength: { value },
                padding: "8px",
                placeholder: "Veuillez entrer votre code",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
              id="username"
              type="text"
              // value={username}
              // onChange={(e) => setUsername(e.target.value)}
            /> */}
              <FormControl sx={{ m: 1, width: "25ch" }} variant="filled">
                <InputLabel
                  style={{ color: message ? "red" : "rgba(219,97,47,0.75)" }}
                  htmlFor="filled-adornment-password"
                >
                  {message ? "Incorrect" : "Entrez le code"}
                </InputLabel>
                <FilledInput
                  error={message ? true : false}
                  onFocus={() => setMessage(false)}
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    color: "#000",
                  }}
                  sx={{
                    '& input[type="password"]::-ms-reveal': { display: "none" },
                    textAlign: "center",
                  }}
                  value={showPassword ? username : hashedCode}
                  onChange={handleChangeCode}
                  color="warning"
                  id="filled-adornment-password"
                  // type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>

            <br />
            <br />
            {/* <button
            type="submit"
            style={{
              width: "100%",
              padding: "8px",
              border: "none",
              fontWeight: "bold",
              textTransform: "none",
              borderRadius: "50px", // Arrondir les coins
              backgroundColor: "#FFFFFF",
              borderBottom: "5px solid #13478A",
              fontFamily: "Caros Soft Medium",
              color: "#451c22",
              cursor: "pointer",
            }}
          >
            Se connecter
          </button> */}
            <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={message ? true : false}
              className="din"
              style={{
                borderRadius: "8px", // Arrondir les coins
                backgroundColor: message
                  ? "rgba(255, 142, 108, 0.5)"
                  : "rgba(219,97,47,0.75)", // Couleur de fond
                color: message ? "gray" : "#fff",
                textTransform: "uppercase",
                textShadow: "2px 1px 2px black",
                width: "25ch",
              }}
            >
              Se connecter
            </Button>
            {message !== false && (
              <div
                style={{
                  textAlign: "center",
                  color: "red",
                }}
              >
                {message}
              </div>
            )}
          </form>
        </div>
      </div>
    </BackgroundImage>
  );
};
