import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { ChakraProvider } from "@chakra-ui/react";

const SpeechModal = ({
  isCustomOpenModal,
  handleCustomCloseModal,
  paroles,
  title,
}) => {
  const newText = paroles
    ?.split("\r\n")
    .map((line, index) => {
      if (/^[A-Z]/.test(line) && index > 0) {
        return `<br>${line}`;
      } else {
        return line;
      }
    })
    .join(" ");
  return (
    <ChakraProvider>
      <Modal isOpen={isCustomOpenModal} onClose={handleCustomCloseModal}>
        <ModalOverlay
          bg="blackAlpha.700"
          backdropFilter="blur(10px) hue-rotate(10deg)"
        />
        <ModalContent
          color="white"
          roundedTop={10}
          //backgroundColor: "rgba(18, 61, 90, 0.9)"
          sx={{ width: "100%", backgroundColor: "transparent" }}
        >
          <ModalHeader>Paroles</ModalHeader>
          <ModalCloseButton color="#118688" />
          <ModalBody p={5}>
            <center>
              <h2 style={{ textTransform: "uppercase" }}>{title}</h2>
              <br />
              <div dangerouslySetInnerHTML={{ __html: newText }}></div>
            </center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default SpeechModal;
