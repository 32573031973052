import "../App.css";
import React, { useEffect, useState, useRef } from "react";
import { Box, Typography, Checkbox } from "@mui/material";
import BackgroundImage from "../BackgroundImage ";
import ImageBackground from "../assets/jenifer_cover_color.jpg";
import ImageBackgroundWeb from "../assets/jenifer_cover_color.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FetchFinalData } from "../dataSong";
import { PostData } from "../dataSong";
import CustomModal from "../components/modal/customModal";
import "moment/locale/fr";
import useSWR from "swr";
import { useAuth } from "../hooks/useAuth";
import SpeechModal from "../components/modal/SpeechModal";
import VirtualizedList from "../components/VirtualizedList";
import moment from "moment";
import CountdownTimer from "../components/CountdownTimer";
import SimpleCountdownTimer from "../components/SimpleCountDownTimer";

const fetcher = (...args) => fetch(...args).then((res) => res.json());
const url = `${process.env.REACT_APP_DOMAIN}/api/endVoteTime`;

const DeFinalPlaylist = () => {
  const [selectedSongs, setSelectedSongs] = useState(Array(6).fill(null));
  const [open, setOpen] = useState(false);
  const [selectedGrid, setSelectedGrid] = useState(null);
  const [fontSize, setFontSize] = useState(10); // Taille de police initiale
  const [isOverflow, setIsOverflow] = useState(false); // État pour indiquer si le texte dépasse
  const boxRef = useRef(null); // Référence pour la boîte
  const textRef = useRef(null); // Référence pour le texte
  const [infoPlayList, setInfoPlaylist] = useState([]);
  const [formaData, setFormData] = useState("");
  const [years, setYear] = useState("");
  const [isLoadingC, setIsLoadingC] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [titleParoles, setTitleParole] = useState();
  const [startVoteTime, setStartVoteTime] = useState(
    new Date().getTime() + 1 * 45 * 1000
  );
  const [endVoteTime, setEndVoteTime] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [isVoteTime, setIsVoteTime] = useState(false);

  //
  const [isOpenModal, setModal] = useState(false);
  const [isCustomOpenModal, setCustomModal] = useState(false);

  const [paroles, setParole] = useState();
  // const [changeColor,  setColor] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [remoteData, setRemoteData] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [tabFinalMusic, setTabFinalMusic] = useState([]);
  const [tabCompare, setTabCompare] = useState([]);

  const { logout } = useAuth();

  const { data, error, isLoading } = useSWR(url, fetcher, {
    revalidateOnFocus: true,
  });
  if (data?.result === true) {
    // console.log("date reached");
    logout();
  }
  if (data?.gotCode !== undefined) {
    if (data?.gotCode !== localStorage.getItem("currentpass")) {
      // console.log("current password:", localStorage.getItem("currentpass"));
      // console.log("passfrom store:", data?.gotCode);
      // console.log("date reached from pass");
      logout();
    }
  }
  // console.log("data from swr:", data);
  // fetch data
  const fetchedData = async () => {
    try {
      // console.log("hasvotedUser:", localStorage.getItem("hasUserVoted"));
      // setIsLoadingData(true);
      const results = await FetchFinalData();
      setYear("2025");
      // const originalDate = results.playListInfo.dateCreation;
      const dateDebutVote = results.data.playListInfo.dateDebutVote;
      const dateFinVote = results.data.playListInfo.dateCreation;
      // console.log("dateCreation :", dateFinVote);

      setStartVoteTime(
        moment(dateDebutVote.toString().slice(0, -1)).utc().valueOf()
      );
      setEndVoteTime(
        moment(dateFinVote.toString().slice(0, -1)).utc().valueOf()
      );

      const formattedDate = moment(results.data.playListInfo.dateFinVote)
        .locale("fr")
        .format("DD MMMM YYYY");
      setFormData(formattedDate);

      setInfoPlaylist(results.data.playListInfo);
      const tabchoice = [];
      const chunkedData = [];

      chunkedData.push(results.data.data);
      const updatedChunk = chunkedData.map((innerArray) => {
        return innerArray.map((obj) => {
          return { ...obj, isSelected: true };
        });
      });
      setRemoteData(updatedChunk);
      // console.log("chunked", chunkedData);

      // console.log("taille ===> ", tabchoice.length);
      setTabCompare(tabchoice);
      // console.log("chunked", chunkedData);

      if (results.data.length > 0) {
        setIsLoadingData(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchedData();
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModal(false);
  };

  const handleConfirmModal = async () => {
    try {
      setIsLoadingC(true);
      // console.log("valiade =======> ", JSON.stringify(tabFinalMusic));
      const response = await PostData(tabFinalMusic);

      // console.log("response", JSON.stringify(response));
      // Utilisez la réponse si nécessaire
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingC(false); // Désactiver le loader
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    const boxHeight = boxRef.current?.clientHeight; // Hauteur de la boîte
    const textHeight = textRef.current?.clientHeight; // Hauteur du texte

    // Si la hauteur du texte est supérieure à la hauteur de la boîte
    if (textHeight > boxHeight) {
      setIsOverflow(true); // Définir l'état de débordement sur true
      setFontSize((prevSize) => prevSize - 1); // Diminuer la taille de police
    } else {
      setIsOverflow(false); // Définir l'état de débordement sur false
    }
  }, [fontSize]);

  useEffect(() => {
    // setTimeout(5000);
    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const remaining = startVoteTime - currentTime;
      if (remaining > 1000) setTimeRemaining(remaining);
      if (remaining < 1000) {
        // setIsVoteTime(true);
        const remaining = endVoteTime - currentTime;
        // if (remaining < 1000) {
        //   navigate("/videoPage");
        // }
        setTimeRemaining(remaining);
      }
    }, 1000); // Mettre à jour toutes les secondes

    return () => clearInterval(interval); // Nettoyer le setInterval lorsque le composant est démonté
  }, [startVoteTime]);

  const handleOpen = (gridIndex, songId) => {
    setSelectedGrid(gridIndex); // Mettre à jour l'index de la grille sélectionnée
    setModalData(songId);
    setOpen(true);
  };

  const handleParoles = (Parole, title) => {
    setParole(Parole);
    setTitleParole(title);
    setCustomModal(true);
  };
  const handleCustomCloseModal = () => {
    setCustomModal(false);
  };
  // console.log("isVoteTime :", isVoteTime);

  return (
    <BackgroundImage
      imageUrlMobile={ImageBackground}
      imageUrlDesktop={ImageBackgroundWeb}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "auto",
          paddingTop: 1,
          alignContent: "center",
        }}
      >
        <Box
          sx={{
            height: 40,
            "@media (min-height:740px)": {
              marginBottom: "50px",
            },
            "@media (min-height:800px)": {
              marginBottom: "84px",
            },
            "@media (min-height:900px)": {
              marginBottom: "100px",
            },
          }}
        >
          <Typography
            style={{
              color: "#FFF",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              lineHeight: "18px",
            }}
          >
            <span
              className="allenoire"
              style={{ fontSize: "24px", textTransform: "uppercase" }}
            >
              {infoPlayList?.nomplaylis}
            </span>
            <span
              className="din"
              style={{
                textShadow: "rgb(0, 0, 0) 1px 1px 5px",
                fontSize: "12px",
              }}
            >
              {formaData}
            </span>
          </Typography>
        </Box>
        {/* <Box>
          <Typography
            variant="h2"
            style={{
              color: "rgb(251, 221, 46, 0.9)",
              textAlign: "center",
              textShadow: "rgb(0, 0, 0) 1px 1px 7px",
            }}
          >
            <span className="budmo">JUKE BOX</span>
          </Typography>
          <Typography
            variant="h2"
            style={{
              color: "#fff",
              textAlign: "center",
              marginTop: -28,
              marginBottom: 10,
            }}
          >
            <span
              className="budmo"
              style={{
                color: "rgb(251, 221, 46, 0.9)",
                textShadow: "rgb(0, 0, 0) 1px 1px 7px",
              }}
            >
              TOUR <span className="octothorpe">{years}</span>
            </span>
          </Typography>
        </Box> */}
        {!isVoteTime ? (
          <Typography
            variant="h7"
            component="h6"
            className="din"
            style={{
              color: "#fff",
              textAlign: "center",
              fontSize: "28px",
              fontWeight: "bold",
              marginTop: "150px",
            }}
            gutterBottom
          >
            <span>ÊTES-VOUS PRÊTS ?</span>
          </Typography>
        ) : (
          <Typography
            variant="h7"
            component="h6"
            className="din"
            style={{
              color: "#fff",
              textAlign: "center",
              fontSize: "20px",
              fontWeight: "bold",
              marginTop: "150px",
            }}
            gutterBottom
          >
            <span>Playlist Définitive</span>
          </Typography>
        )}
        {!isVoteTime ? (
          <Typography
            component="h1"
            sx={{
              color: "white",
              marginTop: "45%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {endVoteTime && startVoteTime ? (
              <CountdownTimer
                setIsVoteTime={setIsVoteTime}
                size={80}
                strokeWidth={3}
                startTime={startVoteTime}
                endTime={endVoteTime}
              />
            ) : (
              <SimpleCountdownTimer start duration={45000} size={80} />
            )}
            <p
              style={{
                textAlign: "center",
                maxWidth: "150px",
              }}
            >
              Avant de découvrir la playlist définitive
            </p>
          </Typography>
        ) : (
          <VirtualizedList
            remoteData={remoteData}
            boxRef={boxRef}
            selectedSongs={selectedSongs}
            handledes={handleParoles}
            handleOpen={handleOpen}
            fontSize={fontSize}
            isComputePage={false}
            isFinalPage={true}
          />
        )}
      </Box>
      <CustomModal
        isOpen={isModalOpen}
        tabFinalMusics={tabFinalMusic}
        onClose={handleCloseModal}
        onConfirm={handleConfirmModal}
        isLoading={isLoadingC}
      />
      <SpeechModal
        handleCustomCloseModal={handleCustomCloseModal}
        paroles={paroles}
        title={titleParoles}
        isCustomOpenModal={isCustomOpenModal}
      />
    </BackgroundImage>
  );
};

export default DeFinalPlaylist;
